import React from 'react'
import WoredaHeader from "../../components/ManagementDD/Woredas/Header";
import WoredasD from "../../components/ManagementDD/Woredas/WoredasD"
function Woredas() {
  return (
    <div>
      <WoredaHeader/>
      <WoredasD/>
    </div>
  )
}

export default Woredas