import React, { useState, useEffect } from "react";
import { Card, Button, Pagination } from "antd";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import axios from "axios";
import Loader from "../../components/Loader"; // Import the Loader component

const NewsList = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const itemsPerPage = 8;

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1000, once: true });

    // Fetch news items from the backend
    const fetchNews = async () => {
      try {
        // const response = await axios.get('kirkossubcitymain.api.ethioalert.com/api/news');
        const response = await axios.get("https://kirkossubcitymain.api.ethioalert.com/api/news"
        );
        setNewsItems(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchNews();
  }, []);

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Format date in European style (DD/MM/YYYY)
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="min-h-screen bg-[#ffffff] p-6">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Loader />{" "}
        </div> // Show loader while loading
      ) : (
        <>
          <h1 className="text-4xl font-bold text-center mb-12 text-white">
            News
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {currentItems.map((item, index) => (
              <motion.div
                key={index}
                data-aos="fade-up"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, type: "spring" }}
                className="transition-transform transform hover:scale-105"
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <Card
                  cover={
                    <div className="relative">
                      <img
                        alt={item.title}
                        src={`https://kirkossubcitymain.api.ethioalert.com/${item.image}`}
                        className="w-full h-40 object-fill rounded-t-lg"
                        style={{ objectFit: "fit" }}
                      />
                    </div>
                  }
                  className="shadow-lg rounded-lg bg-[#0a4275] hover:shadow-xl transition-shadow"
                  hoverable
                  style={{ borderRadius: "10px", overflow: "hidden" }}
                >
                  <Card.Meta
                    title={
                      <h2 className="text-xl font-semibold text-yellow-600">
                        {item.title}
                      </h2>
                    }
                    description={
                      <div>
                        <p className="text-white mb-2">
                          {item.description.length > 200
                            ? `${item.description.substring(0, 200)}...`
                            : item.description}
                        </p>
                        <p className="text-sm text-yellow-500 mb-4">
                          {formatDate(item.date)}
                        </p>
                        <Button
                          type="primary"
                          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          style={{ transition: "background-color 0.3s ease" }}
                        >
                          <a href={`/news/${item._id}`} className="text-white">
                            Read More
                          </a>
                        </Button>
                      </div>
                    }
                  />
                </Card>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              total={newsItems.length}
              pageSize={itemsPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
              className="text-center"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NewsList;
