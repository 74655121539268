import React from 'react'
import Header from '../components/Management/Header'
function Management() {
  return (
    <div>
      <Header/>
    </div>
  )
}

export default Management