import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const CabinetManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [cabinets, setCabinets] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCabinet, setCurrentCabinet] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchCabinets();
  }, []);

  const fetchCabinets = async () => {
    try {
      const response = await axios.get('https://kirkossubcitymain.api.ethioalert.com/api/cabinets');
      setCabinets(response.data);
    } catch (error) {
      console.error('Error fetching cabinets:', error);
      message.error('Failed to fetch cabinets. Please try again.');
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('title', values.title);
    if (fileList.length > 0) {
      formData.append('img', fileList[0].originFileObj);
    }

    try {
      if (editId) {
        await axios.patch(`https://kirkossubcitymain.api.ethioalert.com/api/cabinets/${editId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Cabinet updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://kirkossubcitymain.api.ethioalert.com/api/cabinets', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Cabinet added successfully!');
      }
      form.resetFields();
      setFileList([]);
      fetchCabinets();
    } catch (error) {
      console.error('Error submitting cabinet data:', error);
      message.error(editId ? 'Failed to update cabinet. Please try again.' : 'Failed to add cabinet. Please try again.');
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.img = imageFile;

      const formData = new FormData();
      formData.append('name', updatedData.name);
      formData.append('title', updatedData.title);
      if (imageFile) formData.append('img', updatedData.img);

      await axios.patch(`https://kirkossubcitymain.api.ethioalert.com/api/cabinets/${currentCabinet._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      message.success('Cabinet updated successfully');
      setIsModalVisible(false);
      fetchCabinets();
    } catch (error) {
      console.error('Error updating cabinet:', error);
      message.error('Failed to update cabinet. Please try again.');
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      name: record.name,
      title: record.title,
    });
    setCurrentCabinet(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://kirkossubcitymain.api.ethioalert.com/api/cabinets/${id}`);
      message.success('Cabinet deleted successfully');
      fetchCabinets();
    } catch (error) {
      console.error('Error deleting cabinet:', error);
      message.error('Failed to delete cabinet. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentCabinet(null);
    setImageFile(null);
  };

  const viewCabinet = (record) => {
    Modal.info({
      title: 'View Cabinet Member',
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Image:</strong> {record.img ? <img src={`https://kirkossubcitymain.api.ethioalert.com/${record.img}`} alt={record.name} style={{ width: '100%', maxHeight: '400px' }} /> : 'No Image'}</p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this cabinet member?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => text.substring(0, 40) + (text.length > 40 ? '...' : ''),
    },
    {
      title: 'Image',
      dataIndex: 'img',
      key: 'img',
      render: (img) => (
        img ? <Image width={100} src={`https://kirkossubcitymain.api.ethioalert.com/${img}`} alt="Cabinet Image" fallback="default-image-url" /> : 'No Image'
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewCabinet(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Image">
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={cabinets} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update Cabinet Member"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Image">
            <input type="file" onChange={handleUpdateFileChange} />
            {currentCabinet && currentCabinet.img && (
              <div style={{ marginTop: 10 }}>
                <img src={`https://kirkossubcitymain.api.ethioalert.com/${currentCabinet.img}`} alt="Current" style={{ width: '100px', height: 'auto' }} />
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CabinetManager;
