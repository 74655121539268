import React from 'react'
import EventHeader from '../../components/AnnouncementDD/Event/Header'
import EventDesc from '../../components/AnnouncementDD/Event/EventDesc'
function Event() {
  return (
    <div>
      <EventHeader/>
      <EventDesc/>
    </div>
  )
}

export default Event