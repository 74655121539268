import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  FaHome,
  FaNewspaper,
  FaBullhorn,
  FaImages,
  FaFileAlt,
  FaBriefcase,
  FaCalendarAlt,
  FaCommentDots,
  FaEnvelope,
  FaSignOutAlt,
  FaUser
} from "react-icons/fa";

import AddNews from "./AddNews";
import AddDirectorMessage from "./AddDirectorMessage";
import ImageUpload from "./ImageUpload";
import AddTenders from "./AddTenders";
import AddVacancy from "./AddVacancy";
import AddEvent from "./AddEvent";
import ComplaintsList from "./ComplaintsList";
import ViewFeedback from "./ViewFeedback";
import AdminWelcomePage from "./AdminWelcomePage";
import QuickMessage from "./QuickMessage";
import AddCabinet from "./AddCabinet";
import UserManager from "./UserManager";
import OfficeManager from "./OfficeManager";
import AddOffices from "./AddOffices";
import AddWoredas from "./AddWoredas";
import AddData from "./addData";
import AddCEO from "./AddCEO";
import AddOrgStruct from "./AddOrgStruct";
import Welcomepage from "./Welcompage"

const NavbarA = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role');
    const UserName= localStorage.getItem('username');
    
    if (token && storedRole) {
      setIsAuthenticated(true);
      setRole(storedRole);
    } else {
      setIsAuthenticated(false);
      setRole(null);
    }
  }, []);

  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    setIsAuthenticated(false);
    setRole(null);
    navigate('/login');
  };

  const renderNavLinks = () => {
    if (!isAuthenticated) return null;

    if (role === "admin") {
      return (
        <>
          {/* <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "welcomePage" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("welcomePage")}>
            <FaHome className="mr-2" /> Profile
          </a> */}
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "adddirector" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("adddirector")}>
            <FaBullhorn className="mr-2" /> Add Director Message
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addnews" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addnews")}>
            <FaNewspaper className="mr-2" /> Add News
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addcabinet" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addcabinet")}>
            <FaBriefcase className="mr-2" /> Add Cabinet
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addgallery" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addgallery")}>
            <FaImages className="mr-2" /> Add Gallery
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addoffices" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addoffices")}>
            <FaFileAlt className="mr-2" /> Add Office
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addworedas" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addworedas")}>
            <FaFileAlt className="mr-2" /> Add Woreda
          </a>
          {/* <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addOrgStruct" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addOrgStruct")}>
            <FaFileAlt className="mr-2" /> Add Org Structure
          </a> */}
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addData" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addData")}>
            <FaFileAlt className="mr-2" /> Add Data
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addCEO" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addCEO")}>
            <FaFileAlt className="mr-2" /> Add CEO
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addtenders" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addtenders")}>
            <FaFileAlt className="mr-2" /> Add Tenders
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addvacancy" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addvacancy")}>
            <FaBriefcase className="mr-2" /> Add Vacancy
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addevent" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addevent")}>
            <FaCalendarAlt className="mr-2" /> Add Event
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "officemanager" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("officemanager")}>
            <FaCalendarAlt className="mr-2" /> Add Office Floor
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addquickmessage" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addquickmessage")}>
            <FaCalendarAlt className="mr-2" /> Add Quick Message
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "viewfeedback" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("viewfeedback")}>
            <FaCommentDots className="mr-2" /> View Feedback
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "viewcomplaints" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("viewcomplaints")}>
            <FaEnvelope className="mr-2" /> View Complaints
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "usermanager" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("usermanager")}>
            <FaUser className="mr-2" /> User Manager
          </a>
        </>
      );
    } else if (role === "communication") {
      return (
        <>

{/* <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "welcomePage" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("welcomePage")}>
            <FaHome className="mr-2" /> Profile
          </a> */}
                  <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "adddirector" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("adddirector")}>
            <FaBullhorn className="mr-2" /> Add Director Message
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addnews" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addnews")}>
            <FaNewspaper className="mr-2" /> Add News
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addcabinet" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addcabinet")}>
            <FaBriefcase className="mr-2" /> Add Cabinet
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addgallery" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addgallery")}>
            <FaImages className="mr-2" /> Add Gallery
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addoffices" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addoffices")}>
            <FaFileAlt className="mr-2" /> Add Office
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addworedas" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addworedas")}>
            <FaFileAlt className="mr-2" /> Add Woreda
          </a>
          {/* <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addOrgStruct" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addOrgStruct")}>
            <FaFileAlt className="mr-2" /> Add Org Structure
          </a> */}
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addData" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addData")}>
            <FaFileAlt className="mr-2" /> Add Data
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addCEO" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addCEO")}>
            <FaFileAlt className="mr-2" /> Add CEO
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addtenders" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addtenders")}>
            <FaFileAlt className="mr-2" /> Add Tenders
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addvacancy" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addvacancy")}>
            <FaBriefcase className="mr-2" /> Add Vacancy
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "addevent" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("addevent")}>
            <FaCalendarAlt className="mr-2" /> Add Event
          </a>
          {/* <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "viewfeedback" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("viewfeedback")}>
            <FaCommentDots className="mr-2" /> View Feedback
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "viewcomplaints" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("viewcomplaints")}>
            <FaEnvelope className="mr-2" /> View Complaints
          </a> */}
        </>
      );
    } else if (role === "compliant") {
      return (
        <>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "viewfeedback" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("viewfeedback")}>
            <FaCommentDots className="mr-2" /> View Feedback
          </a>
          <a href="#" className={`flex items-center px-6 py-3 text-lg font-semibold ${currentPage === "viewcomplaints" ? "text-yellow-500 bg-[#0a4275]" : "text-white hover:bg-[#084c77]"}`} onClick={() => navigateToPage("viewcomplaints")}>
            <FaEnvelope className="mr-2" /> View Complaints
          </a>
        </>
      );
    }

    return null;
  };

  return (
    <div className="min-h-screen bg-[#b7bfc5] text-white">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="flex flex-col space-y-2  w-full sm:w-72 bg-[#0a4275] text-white">
          {renderNavLinks()}
          <a href="#" className="flex items-center px-6 py-3 text-lg font-semibold text-white hover:bg-[#084c77]" onClick={handleLogout}>
            <FaSignOutAlt className="mr-2" /> Logout
          </a>
        </div>
        <div className="flex-grow p-6">
          {/* {currentPage === "home" && <AdminWelcomePage />} */}
          {currentPage === "addnews" && <AddNews />}
          {currentPage === "adddirector" && <AddDirectorMessage />}
          {currentPage === "addgallery" && <ImageUpload />}
          {currentPage === "addtenders" && <AddTenders />}
          {currentPage === "addvacancy" && <AddVacancy />}
          {currentPage === "addevent" && <AddEvent />}
          {currentPage === "viewcomplaints" && <ComplaintsList />}
          {currentPage === "viewfeedback" && <ViewFeedback />}
          {currentPage === "addquickmessage" && <QuickMessage />}
          {currentPage === "addcabinet" && <AddCabinet />}
          {currentPage === "usermanager" && <UserManager />}
          {currentPage === "officemanager" && <OfficeManager />}
          {currentPage === "addoffices" && <AddOffices />}
          {currentPage === "addworedas" && <AddWoredas />}
          {currentPage === "addData" && <AddData />}
          {currentPage === "addCEO" && <AddCEO />}
          {currentPage === "addOrgStruct" && <AddOrgStruct />}
          {currentPage === "welcomePage" && <Welcomepage />}
        </div>
      </div>
    </div>
  );
};

export default NavbarA;
