import React from 'react'
import VacancyHeader from '../../components/AnnouncementDD/Vacancy/Header'
import VacancyDesc from '../../components/AnnouncementDD/Vacancy/VacancyDesc'
function Vacancy() {
  return (
    <div>
    <VacancyHeader/>
    <VacancyDesc/>
    </div>
  )
}

export default Vacancy