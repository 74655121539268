import React from 'react'
import Header from '../components/Contact/Header'
import Contactus from "../components/Contact/ContactDesc"
import Location from "../components/Contact/Location"
function Contact() {
  return (
    <div>
      <Header/>
      <Contactus/>
      <Location/>
    </div>
  )
}

export default Contact