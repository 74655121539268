import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";

// Sample data
const categories = {
  ceoPool: [
    { name: 'Administration Finance Office' },
    { name: 'Council Office' },
    { name: 'Peace and Security Office' },
    { name: 'Prosecutor\'s Office' },
    { name: 'Office of Regulation Enforcement' },
    { name: 'Planning Commission Office' },
    { name: 'Trade Office' },
    { name: 'Cooperative Office' },
    { name: 'Good Governance Complaints and Appeals Office' },
    { name: 'Farmers and Urban Agriculture Office' },
    { name: 'Renaissance Dam Construction Coordination Office' },
  ],
  publicPool: [
    { name: 'Health Office' },
    { name: 'Women, Children and Social Affairs Office' },
    { name: 'Culture, Arts and Tourism Office' },
    { name: 'Public Service and Human Resources Development Office' },
    { name: 'Government Property Management Office' },
    { name: 'Communication Office' },
    { name: 'Administration Finance Office' }
  ],
  managerPool: [
    { name: 'Administration and Green Development Office' },
    { name: 'Sanitation Management Office' },
    { name: 'Local Safety Office' },
    { name: 'Civil Registration and Resident Service Office' },
    { name: 'Employment Pool Coordination Office' },
    { name: 'Administration Finance Office' },
    { name: 'Job Skills Office' },
    { name: 'Industrial Development Office' },
    { name: 'Jobs Office' },
    { name: 'Community Participation and Good Will Coordination Office' },
    { name: 'Innovation and Technology Development Office' },
    { name: 'Housing Office' },
    { name: 'Construction Office' },
  ],
  designPool: [
    { name: 'Building Permit Office' },
    { name: 'Construction Office' },
    { name: 'Housing Office' },
    { name: 'Job Skills Office' },
    { name: 'Industrial Development Office' },
    { name: 'Jobs Office' },
    { name: 'Community Participation and Good Will Coordination Office' },
    { name: 'Innovation and Technology Development Office' },
    { name: 'Administration Finance Office' },
  ]
};

const OrganizationStructure = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white text-[#0a4275] py-16 px-4 lg:px-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-extrabold text-center mb-12 text-[#f5a623]">{t('Organizational Structure')}</h1>
        <div className="flex flex-col items-center mb-12">
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          >
            <h2 className="text-2xl font-bold mb-4 bg-[#0a4275] text-white p-4 rounded-lg">
              {t('Chief Executive Manager')}
            </h2>
          </motion.div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          >
            <h3 className="text-xl font-bold mb-4 bg-[#0a4275] text-white p-4 rounded-lg">
              {t('CEO Pool')}
            </h3>
            <div className="grid grid-cols-1 gap-6">
              {categories.ceoPool.map((office, index) => (
                <div key={index} className="flex flex-col items-center bg-[#f5f5f5] text-[#0a4275] p-4 rounded-lg shadow-lg">
                  <h3 className="text-xl font-semibold text-center">{t(office.name)}</h3>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          >
            <h3 className="text-xl font-bold mb-4 bg-[#0a4275] text-white p-4 rounded-lg">
              {t('Public Pool')}
            </h3>
            <div className="grid grid-cols-1 gap-6">
              {categories.publicPool.map((office, index) => (
                <div key={index} className="flex flex-col items-center bg-[#f5f5f5] text-[#0a4275] p-4 rounded-lg shadow-lg">
                  <h3 className="text-xl font-semibold text-center">{t(office.name)}</h3>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          >
            <h3 className="text-xl font-bold mb-4 bg-[#0a4275] text-white p-4 rounded-lg">
              {t('Manager Pool')}
            </h3>
            <div className="grid grid-cols-1 gap-6">
              {categories.managerPool.map((office, index) => (
                <div key={index} className="flex flex-col items-center bg-[#f5f5f5] text-[#0a4275] p-4 rounded-lg shadow-lg">
                  <h3 className="text-xl font-semibold text-center">{t(office.name)}</h3>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
          >
            <h3 className="text-xl font-bold mb-4 bg-[#0a4275] text-white p-4 rounded-lg">
              {t('Design and Construction Pool')}
            </h3>
            <div className="grid grid-cols-1 gap-6">
              {categories.designPool.map((office, index) => (
                <div key={index} className="flex flex-col items-center bg-[#f5f5f5] text-[#0a4275] p-4 rounded-lg shadow-lg">
                  <h3 className="text-xl font-semibold text-center">{t(office.name)}</h3>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationStructure;
