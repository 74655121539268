import React from "react";
import OfficesHeader from "../../components/ManagementDD/Offices/Header";
import OfficeDesc from "../../components/ManagementDD/Offices/OfficesDesc";
function Offices() {
  return (
    <div>
      <OfficesHeader />
      <OfficeDesc/>
    </div>
  );
}

export default Offices;
