import React from 'react'
import CabinetsHeader from "../../components/ManagementDD/Cabinets/Header"
import CabinetsD from "../../components/ManagementDD/Cabinets/CabinetsD"
function Cabinets() {
  return (
    <div>
      <CabinetsHeader/>
      <CabinetsD/>
      
    </div>
  )
}

export default Cabinets