import React from 'react'
import Header from '../components/Gallery/Header'
import GalleryItems from "../components/Gallery/GalleryItem"
function Gallery() {
  return (
    <div>
      <Header/>
      <GalleryItems/>
    </div>
  )
}

export default Gallery