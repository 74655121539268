import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ChiefExecutive = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [chiefExecutives, setChiefExecutives] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentChiefExecutive, setCurrentChiefExecutive] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchChiefExecutives();
  }, []);

  const fetchChiefExecutives = async () => {
    try {
      const response = await axios.get('https://kirkossubcitymain.api.ethioalert.com/api/chief-executive');
      setChiefExecutives(response.data);
    } catch (error) {
      console.error('Error fetching chief executives:', error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('title', values.title);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('fax', values.fax || '');
    formData.append('location', values.location);
    formData.append('facebook', values.facebook || '');
    formData.append('twitter', values.twitter || '');
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      if (editId) {
        await axios.patch(`https://kirkossubcitymain.api.ethioalert.com/api/chief-executive/${editId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Chief Executive updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://kirkossubcitymain.api.ethioalert.com/api/chief-executive', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('Chief Executive added successfully!');
      }
      form.resetFields();
      setFileList([]);
      fetchChiefExecutives();
    } catch (error) {
      console.error('Error submitting chief executive data:', error);
      message.error(editId ? 'Failed to update chief executive. Please try again.' : 'Failed to add chief executive. Please try again.');
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append('name', updatedData.name);
      formData.append('title', updatedData.title);
      formData.append('phone', updatedData.phone);
      formData.append('email', updatedData.email);
      formData.append('fax', updatedData.fax || '');
      formData.append('location', updatedData.location);
      formData.append('facebook', updatedData.facebook || '');
      formData.append('twitter', updatedData.twitter || '');
      if (imageFile) formData.append('image', updatedData.image);

      await axios.patch(`https://kirkossubcitymain.api.ethioalert.com/api/chief-executive/${currentChiefExecutive._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      message.success('Chief Executive updated successfully');
      setIsModalVisible(false);
      fetchChiefExecutives();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      name: record.name,
      title: record.title,
      phone: record.phone,
      email: record.email,
      fax: record.fax,
      location: record.location,
      facebook: record.facebook,
      twitter: record.twitter,
    });
    setCurrentChiefExecutive(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://kirkossubcitymain.api.ethioalert.com/api/chief-executive/${id}`);
      message.success('Chief Executive deleted successfully');
      fetchChiefExecutives();
    } catch (error) {
      console.error('Error deleting chief executive:', error);
      message.error('Failed to delete chief executive. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentChiefExecutive(null);
    setImageFile(null);
  };

  const viewMessage = (record) => {
    Modal.info({
      title: 'View Chief Executive',
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Phone:</strong> {record.phone}</p>
          <p><strong>Email:</strong> {record.email}</p>
          <p><strong>Fax:</strong> {record.fax || 'N/A'}</p>
          <p><strong>Location:</strong> {record.location}</p>
          <p><strong>Facebook:</strong> {record.facebook || 'N/A'}</p>
          <p><strong>Twitter:</strong> {record.twitter || 'N/A'}</p>
          <p><strong>Image:</strong> {record.image ? <img src={`https://kirkossubcitymain.api.ethioalert.com/${record.image}`} alt={record.name} style={{ width: '100%', maxHeight: '400px' }} /> : 'No Image'}</p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this chief executive?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => text.substring(0, 15) + (text.length > 15 ? '...' : ''),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        image ? <Image width={100} src={`https://kirkossubcitymain.api.ethioalert.com/${image}`} alt="Chief Executive Image" fallback="default-image-url" /> : 'No Image'
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewMessage(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input the phone!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input the email!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="fax" label="Fax">
          <Input />
        </Form.Item>
        <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Please input the location!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="facebook" label="Facebook">
          <Input />
        </Form.Item>
        <Form.Item name="twitter" label="Twitter">
          <Input />
        </Form.Item>
        <Form.Item label="Image">
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={chiefExecutives} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update Chief Executive"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input the phone!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input the email!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="fax" label="Fax">
            <Input />
          </Form.Item>
          <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Please input the location!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="facebook" label="Facebook">
            <Input />
          </Form.Item>
          <Form.Item name="twitter" label="Twitter">
            <Input />
          </Form.Item>
          <Form.Item label="Image">
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChiefExecutive;
