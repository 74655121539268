import React from 'react'
import ChiefExecutiveHeader from "../../components/ManagementDD/ChiefExecutive/Header"
import ChiefExecutiveD from "../../components/ManagementDD/ChiefExecutive/ChiefExecutiveD"
function ChiefExecutive() {
  return (
    <div>
      <ChiefExecutiveHeader/>
      <ChiefExecutiveD/>
    </div>
  )
}

export default ChiefExecutive