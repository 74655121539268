import React from 'react';
import { useTranslation } from "react-i18next";

function Welcome2() {
    const { t } = useTranslation();
  return (
    <div className='w-full h-[20vh] flex justify-center items-center bg-blue-800'>
        <p 
          className='text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white text-center'
          data-aos="fade-up" 
          data-aos-duration="1000" 
          data-aos-delay="200"
        >
          {t('Welcome to Kirkos Subcity Administration')}
        </p>
    </div>
  )
}

export default Welcome2;
