import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import arrow icons from React Icons

const BuildingFloors = () => {
  const { t } = useTranslation();
  const [floors, setFloors] = useState({});
  const [hoveredFloor, setHoveredFloor] = useState(null);
  const [expandedFloor, setExpandedFloor] = useState(null); // State to track the currently expanded floor

  useEffect(() => {
    fetchFloors();
  }, []);

  const fetchFloors = async () => {
    try {
      const response = await axios.get('https://kirkossubcitymain.api.ethioalert.com/api/offices');
      const floorsData = response.data.reduce((acc, floorData) => {
        const floor = floorData.floor;
        const offices = floorData.offices[0].split(',').map(office => office.trim());
        acc[floor] = offices;
        return acc;
      }, {});
      setFloors(floorsData);
    } catch (error) {
      console.error('Error fetching office data:', error);
    }
  };

  const handleMouseEnter = (floor) => {
    setHoveredFloor(floor);
  };

  const handleMouseLeave = () => {
    setHoveredFloor(null);
  };

  const toggleFloor = (floor) => {
    if (expandedFloor === floor) {
      setExpandedFloor(null); // Close the floor if it is already expanded
    } else {
      setExpandedFloor(floor); // Expand the clicked floor and collapse any previously expanded floor
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 mx-auto text-center lg:text-4xl text-yellow-600">{t('Building Offices')}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.entries(floors).map(([floor, offices]) => (
          <div
            key={floor}
            className={`p-4 border rounded shadow-lg transition-transform duration-300 bg-[#0a4275] ${
              hoveredFloor === floor ? 'transform scale-105' : ''
            }`}
            onMouseEnter={() => handleMouseEnter(floor)}
            onMouseLeave={handleMouseLeave}
            onClick={() => toggleFloor(floor)} // Toggle floor on click
          >
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2 text-white">{floor}</h2>
              {expandedFloor === floor ? (
                <FaChevronUp className="text-white cursor-pointer" />
              ) : (
                <FaChevronDown className="text-white cursor-pointer" />
              )}
            </div>
            {expandedFloor === floor && ( // Show offices only if the current floor is expanded
              <ul className="list-disc pl-5 text-white">
                {offices.map((office, index) => (
                  <li key={index} className="py-1">
                    {office}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuildingFloors;
