import React from 'react';
import { useTranslation } from "react-i18next";

function Location() {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-full">
        <div className='text-4xl lg:text-5xl font-extrabold text-center text-[#0a4275]'>
            <h1>{t('Our Location')}</h1>
        </div>
      <iframe
       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.585133794196!2d38.76353797490901!3d9.010266691050319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85bb716dde79%3A0x42b9998ef308d035!2sKirkos%20SubCity%20Administration!5e0!3m2!1sen!2set!4v1721467511477!5m2!1sen!2set"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Kirkos Sub City Administration"
        className="w-full h-96 sm:h-[450px]"
      ></iframe>
    </div>
  );
}

export default Location;
