import React from 'react'
import Header from '../components/Compliant/Header'
import ComplaintForm from "../components/Compliant/CompliantForm"
function Compliant() {
  return (
    <div>
      <Header/>
      <ComplaintForm/>
    </div>
  )
}

export default Compliant