import React from 'react';

const Spinner = () => {
  return (
    <div className="relative w-32 h-32">
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="spinner"></div>
      </div>
      <style jsx>{`
        .spinner {
          --size: 50px; /* Increased size */
          --first-block-clr: #005bba;
          --second-block-clr: #fed500;
          --clr: #111;
          width: 120px; /* Increased width */
          height: 120px; /* Increased height */
          position: relative;
        }

        .spinner::after, .spinner::before {
          box-sizing: border-box;
          position: absolute;
          content: "";
          width: var(--size);
          height: var(--size);
          top: 50%;
          animation: up 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
          left: 50%;
          background: var(--first-block-clr);
        }

        .spinner::after {
          background: var(--second-block-clr);
          top: calc(50% - var(--size));
          left: calc(50% - var(--size));
          animation: down 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
        }

        @keyframes down {
          0%, 100% {
            transform: none;
          }
          25% {
            transform: translateX(100%);
          }
          50% {
            transform: translateX(100%) translateY(100%);
          }
          75% {
            transform: translateY(100%);
          }
        }

        @keyframes up {
          0%, 100% {
            transform: none;
          }
          25% {
            transform: translateX(-100%);
          }
          50% {
            transform: translateX(-100%) translateY(-100%);
          }
          75% {
            transform: translateY(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default Spinner;
