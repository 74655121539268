import React from "react";
import Description from "../components/About/Description";
import AboutUs from "../components/About/AboutUs";
import Header from "../components/About/Header";
import DataChart from "../components/Home/DataChart ";
import BuildingFloors from "../components/Home/BuildingFloors";
import NavbarA from "../components/Admin/NavbarA";
function About() {
  return (
    <div>
      {/* <Description/> */}
      {/* <NavbarA/> */}
      <Header />
      <AboutUs />
      <Description />
      <BuildingFloors />
      <DataChart />
      {/* <NavbarA/> */}
    </div>
  );
}

export default About;
