import React from 'react'
import StructureHeader from "../../components/ManagementDD/Structure/Header";
import StructureD from "../../components/ManagementDD/Structure/StructureD"
function Structure() {
  return (
    <div>
      <StructureHeader/>
      <StructureD/>
    </div>
  )
}

export default Structure