import React from "react";
import Carousel from "../components/Home/Carousel";
import Counter from "../components/Home/Counter";
import DirectorMessage from "../components/Home/DirectorMessage";
import Offices from "../components/Home/Offices";
// import News from "../components/Home/News"
import News from "../components/News/News";
import Contact from "../components/Contact/ContactDesc";
import Banner from "../components/Home/Banner";
import BuildingFloors from "../components/Home/BuildingFloors";
import DataChart from "../components/Home/DataChart ";
import OfficeDescription from "../components/ManagementDD/Offices/OfficesDesc";
import Welcome2 from "../components/Home/Welcome2";
function Home() {
  return (
    <div>
     
      <Carousel />
      <DirectorMessage />
      <Welcome2/>
      <Counter />
      
      <OfficeDescription />
      <BuildingFloors />
      <div className="bg-blue-500 p-4">
        <News />
      </div>

      <div>
        <DataChart />
        <Contact />
      </div>
      <Banner />
    </div>
  );
}

export default Home;
