import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const NewsManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [news, setNews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get('https://kirkossubcitymain.api.ethioalert.com/api/news');
      setNews(response.data);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = (e) => setImageFile(e.target.files[0]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      if (editId) {
        await axios.patch(`https://kirkossubcitymain.api.ethioalert.com/api/news/${editId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('News updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://kirkossubcitymain.api.ethioalert.com/api/news', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        message.success('News added successfully!');
      }
      form.resetFields();
      setFileList([]);
      fetchNews();
    } catch (error) {
      console.error('Error submitting news data:', error);
      message.error(editId ? 'Failed to update news. Please try again.' : 'Failed to add news. Please try again.');
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      const updatedData = { ...values };
      if (imageFile) updatedData.image = imageFile;

      const formData = new FormData();
      formData.append('title', updatedData.title);
      formData.append('description', updatedData.description);
      if (imageFile) formData.append('image', updatedData.image);

      await axios.patch(`https://kirkossubcitymain.api.ethioalert.com/api/news/${currentNews._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      message.success('News updated successfully');
      setIsModalVisible(false);
      fetchNews();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      title: record.title,
      description: record.description,
    });
    setCurrentNews(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://kirkossubcitymain.api.ethioalert.com/api/news/${id}`);
      message.success('News deleted successfully');
      fetchNews();
    } catch (error) {
      console.error('Error deleting news:', error);
      message.error('Failed to delete news. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentNews(null);
    setImageFile(null);
  };

  const viewMessage = (record) => {
    Modal.info({
      title: 'View News',
      content: (
        <div>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Description:</strong> {record.description}</p>
          <p><strong>Image:</strong> {record.image ? <img src={`https://kirkossubcitymain.api.ethioalert.com/${record.image}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} /> : 'No Image'}</p>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this news item?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text.substring(0, 40) + (text.length > 40 ? '...' : ''),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        image ? <Image width={100} src={`https://kirkossubcitymain.api.ethioalert.com/${image}`} alt="News Image" fallback="default-image-url" /> : 'No Image'
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewMessage(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Image">
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={news} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update News"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Image">
            <input type="file" onChange={handleUpdateFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default NewsManager;
