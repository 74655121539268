import React from 'react'
import TenderHeader from '../../components/AnnouncementDD/Tender/Tender'
import TenderDesc from '../../components/AnnouncementDD/Tender/TenderDesc'
function Tender() {
  return (
    <div>
        <TenderHeader/>
        <TenderDesc/>
    </div>
  )
}

export default Tender