import React from 'react';
import { useTranslation } from "react-i18next";


function Header() {
  const { t } = useTranslation();

  return (
    <div className='h-36 md:h-48 bg-[#0a4275] relative text-center flex flex-col justify-center items-center overflow-hidden'>
      <p className='font-bold text-2xl md:text-4xl text-white animate-fadeIn'>
        {t('What Our Photos Say!')}
      </p>
      <p className='font-medium text-lg md:text-2xl text-white mt-2 animate-fadeIn'>
        {t('Gallery')}
        <span className="text-gray-300 mx-2">|</span>
        <a href='/' className='text-blue-300 hover:text-blue-500 transition-colors duration-300'>
          {t('Home')}
        </a>
      </p>
      <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-white rounded-full'></div>
    </div>
  );
}

export default Header;


// import React from 'react';
// import { useTranslation } from "react-i18next";
// function Header() {
//   const { t } = useTranslation();
//   return (
//     <div className='h-48 md:h-64 bg-[#0a4275] relative text-center flex flex-col justify-center items-center'>
//       <p className='font-bold text-3xl md:text-6xl text-white animate-fadeIn'>{t('What Our Photos Say!')}</p>
//       <p className='font-bold text-2xl md:text-4xl text-white mt-2 md:mt-4 animate-fadeIn'>
//         {t('Gallery')} | <a href='/' className='text-blue-300 hover:text-blue-500 transition-colors duration-300'><span>{t('Home')}</span></a>
//       </p>
//       <div className='absolute bottom-0 left-0 right-0 h-1 '></div>
//     </div>
//   )
// }

// export default Header;

