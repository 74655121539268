import React from 'react'
import Header from '../components/Announcement/Header'
function Announcement() {
  return (
    <div>
    <Header/>
    </div>
  )
}

export default Announcement